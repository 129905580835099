const moment = require('moment');
const vegas = require('vegas');
const matchHeight = require('jquery-match-height');
/**
	* 単純なサンプル
	* moment.jsを使って現在日付をconsole出力するだけ
	*/
$(function () {
	console.log(moment().format("YYYY-MM-DD"));
	$('.matchHeight').matchHeight();
	$(document).on('click', '.nav__open', function () {
		$(this).toggleClass('is-active');
		$(".nav").toggleClass('is-active');
	})
	$(document).on('click', '.anker', function () {
		if ($("#toppage").length) {
			var t = $(this).data('anker');
			$("html, body").animate({
				scrollTop: $(t).offset().top - $(".header").outerHeight()
			});
			$(this).toggleClass('is-active');
			$(".nav").toggleClass('is-active');
			return false;
		}
	})
	$(window).on('load', function () {
		var url = $(location).attr('href');
		if (url.indexOf("#") != -1) {
			var anchor = url.split("#");
			var target = $('#' + anchor[anchor.length - 1]);
			if (target.length) {
				var pos = Math.floor(target.offset().top) - $(".header").outerHeight();
				$("html, body").animate({ scrollTop: pos }, 500);
			}
		}
	});

	//
	$("#slideshow").vegas({
		timer: false,
		delay: 5000,
		slides: [
			{ src: "/assets/img/mainimage1.jpg" },
			{ src: "/assets/img/mainimage2.jpg" }
		]
	});
});
